.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent !important;
}
.batch-btn {
  display: inline-block;
  margin-top: 10px;
}
.el-table .primary-row {
  background: #c6e2ff;
}
.el-table .warning-row {
  background: #fdf6ec;
}
.el-table .success-row {
  background: #f0f9eb;
}
.el-table .danger-row {
  background: #fef0f0;
}
